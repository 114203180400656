<template>
  <full-page title="Invoices Process">
    <RangeSelector :submit-method="getInvoices"/>
    <div class="card mx-2 mb-2">
      <form @submit.prevent="getInvoices" class="card-body">
        <div class="row mb-2">
          <div class="col-md-3">
            <label for="floatingTextInput1">Invoice No.</label>
            <input type="text" class="form-control" v-model="invoice_no" id="floatingTextInput1"
                   placeholder="Invoice No.">
          </div>
          <div class="col-md-3">
            <label for="pay-type">Payment Method</label>
            <select class="form-select" v-model="payType" id="pay-type">
              <option value="">All</option>
              <option v-for="method in methods" :value="method">{{ method }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <label for="sale-total">Sale Total</label>
            <input type="number" v-model="saleTotal" step="0.01" placeholder="SaleTotal" id="sale-total"
                   class="form-control">
          </div>
          <div class="col-md-3">
            <label for="search-limit">Limit</label>
            <input type="number" v-model="limit" placeholder="Limit" id="search-limit" class="form-control">
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-4">
            <input type="radio" id="age1" v-model="isSmartInvoice" :value="null">
            <label for="age1">All</label>
          </div>
          <div class="col-md-4">
            <input type="radio" id="age2" v-model="isSmartInvoice" :value="true">
            <label for="age2">Smart Invoiced</label>
          </div>
          <div class="col-md-4">
            <input type="radio" id="age3" v-model="isSmartInvoice" :value="false">
            <label for="age3">Not Smart Invoiced</label>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary w-75" type="submit">Search</button>
        </div>
      </form>
    </div>
    <line-separator :title="`Invoices (${invoices.length} Found)`" class="mx-3"/>
    <InvoiceRecord v-for="item in invoices" @refreshEvent="getInvoices" @click="openInvoice(item)" :sale="item"
                   :is-approved="(!!item.sentToSmartInvoice)"/>

    <custom-modal :title="`Invoice #`+selectedInvoice.InvNum" ref="invoiceModal" size="md">
      <div class="d-flex flex-column justify-content-center" v-if="selectedInvoice.InvNum">
        <span class="text-primary">Sale Total : {{ $utils.formatMoney(selectedInvoice.Total) }}</span>
        <span class="text-primary">Paid By : {{ selectedInvoice.PaymentType }}</span>
        <a class="btn btn-primary m-2" @click="printInvoice(true)">
          <font-awesome-icon icon="print"/>
          Print
        </a>
        <a class="btn btn-primary m-2" @click="printInvoice(false)">
          <font-awesome-icon icon="print"/>
          Print Original
        </a>

      </div>
    </custom-modal>
  </full-page>
</template>

<script>
import InvoiceRecord from "@/components/point-of-sale/sales/Invoices/InvoiceRecords";
import RangeSelector from "@/components/layout/utils/RangeSelector";
import LineSeparator from "@/components/layout/utils/LineSeparator";
import FullPage from "@/components/layout/utils/FullPage";
import CustomModal from "@/components/layout/utils/CustomModal";


export default {
  name: "SalesRecords",
  components: {CustomModal, FullPage, LineSeparator, RangeSelector, InvoiceRecord},
  computed: {
    methods() {
      let payMethods = [`Cash`, `Card`, `Kazang`];
      let paymentMethods = this.$store.getters.getPaymentMethods.map(method => method.Name);
      return [...payMethods, ...paymentMethods];
    },
  },
  data() {
    return {
      generalOverview: undefined,
      invoice_no: '',
      payType: '',
      saleTotal: '',
      limit: 10,
      invoices: [],
      isSmartInvoice: null,
      selectedInvoice: {}
    }
  },
  beforeMount() {
    this.getInvoices();
  },
  methods: {
    printInvoice(reprint = false) {
      let obj = {
        reprint,
        invoice: this.selectedInvoice.InvNum
      }
      return this.$store.dispatch(`printInvoice`, obj);
    },
    creditInvoice() {
      return Swal.fire({
        title: 'Are you sure?',
        text: "This will Credit The Entire Invoice!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let id = this.$route.query.id;
          let obj = {
            invoiceNumber: this.selectedInvoice.InvNum,
            customerID: id,
          }
          return this.$store.dispatch(`creditEntireInvoice`, obj)
              .then(res => res);
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let obj = {
            reprint: false,
            invoice: this.selectedInvoice.InvNum
          }
          return this.$store.dispatch(`printInvoice`, obj);
        }
      });
    },
    invoiceDetails() {
      this.$utils.showLoading()
      let inv = this.$route.params.invoice;
      return this.$store.dispatch('getInvoiceLines', inv)
          .then(res => {
            this.generalOverview = res;
            this.saleTotal = Number(res.saleTotal)
          }).finally(() => this.$utils.hideLoading())
    },
    retrySmartInvoice() {
      $utils.showLoading();
      return this.$store.dispatch(`retrySmartInvoice`, this.$route.params.invoice)
          .then(() => this.invoiceDetails())
          .finally(() => $utils.hideLoading());
    },
    getInvoices() {
      this.$utils.showLoading()
      let obj = {
        InvNum: this.invoice_no,
        SaleTotal: this.saleTotal.trim() === '' ? null : Number(this.saleTotal),
        PaymentType: this.payType,
        limit: this.limit ? this.limit : null,
      }
      return this.$store.dispatch('getAllInvoices', obj)
          .then(res => this.invoices = res)
          .finally(() => this.$utils.hideLoading())
    },
    openInvoice(inv) {
      console.log(inv)
      this.selectedInvoice = inv;
      this.$refs.invoiceModal.openModal();
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-box {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}

.col-md-4 {
  margin-bottom: 10px;

}
</style>