<template>
  <div class="stock-card" @click="$emit('click')">
    <div class="stock-card__content">
      <!-- Display tick or X here -->
      <div class="d-flex flex-column status-icon">
        <span>{{ statusIcon }}</span>
      </div>
      <div class="stock-card_img">
        <font-awesome-icon class="icon" icon="fa-solid fa-money-bills"/>
      </div>
      <div class="stock-card_info">
        <h6>Invoice No. : #{{ sale.InvNum }} </h6>
        <h6 class="text-muted stock-card_company">{{ sale.Date }}</h6>
      </div>
      <h6>
        <a class="btn btn-sm btn-outline-info" @click.stop.prevent="retrySmartInvoice" v-if="!isApproved && isSmartInvoicing">
          <font-awesome-icon icon="rotate-left"/>
        </a>
        {{ $utils.formatMoney(sale.TotalQty, 2, '.', ',', false) }} Item(s)
      </h6>
    </div>
    <div class="stock-card__footer">
      <div class="stock-card_stock-type">
        <p class="mb-0 fw-bold text-secondary">Total : {{ $utils.formatMoney(sale.Total) }}</p>
      </div>
      <div>

        <p class="mb-0 text-secondary">{{ sale.PaymentType }} </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoiceRecord",
  props: {
    sale: Object,
    isApproved: {
      type: Boolean,
      default: true, // Set to null if you want to randomize
    }
  },
  computed: {
    isSmartInvoicing() {
      return this.$store.getters.getSmartInvoicingStatus;
    },
    statusIcon() {
      if (this.isSmartInvoicing) return '✔️';
      return this.isApproved ? '✔️' : '❌';
    }
  },
  methods: {
    retrySmartInvoice() {
      $utils.showLoading();
      return this.$store.dispatch('retrySmartInvoice', this.sale.InvNum)
          .then(() => this.$emit('refreshEvent'))
          .catch(err => {
            // Call the error handler with the error
            errorHandler.tomcatError(err);
          })
          .finally(() => $utils.hideLoading());
    },
  }
}
</script>

<style lang="scss" scoped>
.icon {
  font-size: 25px;
  margin: 10px;
}

p {
  margin: 0;
}

.stock-card_company {
  font-size: 12px;
}

.stock-card {
  background: #fff;
  padding: 6px 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #d2d2d2;

  &__content {
    display: flex;
    align-items: center;

    .status-icon {
      margin-right: 10px;
      font-size: 20px;
    }

    .stock-card_img > .icon-img {
      margin-right: 10px;
      font-size: 20px;

      img {
        height: 60px;
        width: 60px;
        opacity: 0.7;
        object-fit: contain;
        border-radius: 8px;
      }
    }

    .stock-card_info {
      flex: 1;

      .text-muted a {
        color: #777;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .stock-card_stock-type {
      width: 60%;
    }

    .stock-card-price {
      font-weight: 500;
      font-size: 18px;
      color: #40434A;
    }
  }
}

.stock-label {
  display: inline-block;
  padding: 2px 12px;
  font-size: 10px;
  font-weight: 700;
  background: #f5f5f5;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  margin-right: 2px;
  color: #40434A;
}

h6, h4, p {
  margin: 0;
}

.text-sm {
  font-size: 5px
}
</style>
